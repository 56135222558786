* {
  margin: 0;
  padding: 0;
  background-color: #333;
}
.App {
  inline-size: 600px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}